var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "prof", attrs: { wrap: "", "pt-3": "", "gap-xs-3": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.milestones && _vm.milestones.length
                ? [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      _vm._l(_vm.milestones, function(milestone) {
                        return _c(
                          "v-flex",
                          {
                            key: milestone.id,
                            staticClass: "pa-1 mt-2",
                            attrs: { xs4: "" }
                          },
                          [
                            _c("profile-milestone", {
                              attrs: { milestone: milestone },
                              on: { selected: _vm.onSelect }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "app.profile.my_starling.milestones.subtitle"
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }